import { render, staticRenderFns } from "./CoDelivTotal.vue?vue&type=template&id=1cd1e663&"
import script from "./CoDelivTotal.vue?vue&type=script&lang=js&"
export * from "./CoDelivTotal.vue?vue&type=script&lang=js&"
import style0 from "./CoDelivTotal.vue?vue&type=style&index=0&id=1cd1e663&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports